import { viewLazyLoad } from "@/assets/js/utils"

export default {
  path: "/dashboard",
  component: viewLazyLoad("Dashboard"),
  children: [
    {
      path: "",
      name: "Dashboard Home",
      component: viewLazyLoad("Dashboard/Home"),
      meta: {
        title: "控制面板",
        requiresAuth: true,
      },
    },
    {
      path: "softwares",
      component: viewLazyLoad("Dashboard/Softwares"),
      children: [
        {
          path: "",
          name: "Dashboard Softwares",
          component: viewLazyLoad("Dashboard/Softwares/Home"),
          meta: {
            title: "应用列表",
            requiresAuth: true,
          },
        },
        {
          path: "websites",
          component: viewLazyLoad("Dashboard/Softwares/Websites"),
          children: [
            {
              path: "",
              name: "Dashboard Software Websites",
              component: viewLazyLoad("Dashboard/Softwares/Websites/Home"),
              meta: {
                title: "站点列表",
                requiresAuth: true,
              },
            },
            {
              path: ":websiteId",
              name: "Dashboard Software Website Details",
              component: viewLazyLoad("Dashboard/Softwares/Websites/Details"),
              meta: {
                title: "站点详情",
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "verification",
          name: "Dashboard Software Verification",
          component: viewLazyLoad("Dashboard/Softwares/Verification"),
          meta: {
            title: "正版查询",
            requiresAuth: true,
          },
        },
        {
          path: "nodes",
          name: "Dashboard Software Nodes",
          component: viewLazyLoad("Dashboard/Softwares/Nodes"),
          meta: {
            title: "节点状态",
            requiresAuth: true,
          },
        },
        {
          path: ":softwareId",
          name: "Dashboard Software Details",
          component: viewLazyLoad("Dashboard/Softwares/Details"),
          meta: {
            title: "应用详情",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "projects",
      component: viewLazyLoad("Dashboard/Projects"),
      children: [
        {
          path: "",
          name: "Dashboard Projects",
          component: viewLazyLoad("Dashboard/Projects/Home"),
          meta: {
            title: "项目列表",
            requiresAuth: true,
          },
        },
        {
          path: "create",
          name: "Dashboard Project Details",
          component: viewLazyLoad("Dashboard/Projects/Create"),
          meta: {
            title: "提交需求",
            requiresAuth: true,
          },
        },
        {
          path: "quotes",
          component: viewLazyLoad("Dashboard/Quotes"),
          children: [
            {
              path: "",
              name: "Dashboard Quotes",
              component: viewLazyLoad("Dashboard/Quotes/Home"),
              meta: {
                title: "估价列表",
                requiresAuth: true,
              },
            },
            {
              path: "create",
              name: "Dashboard Quote Create",
              component: viewLazyLoad("Dashboard/Quotes/Create"),
              meta: {
                title: "新增估价",
                requiresAuth: true,
              },
            },
            {
              path: ":quoteId",
              name: "Dashboard Quote Details",
              component: viewLazyLoad("Dashboard/Quotes/Details"),
              meta: {
                title: "评估详情",
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "pricings",
          name: "Dashboard Pricings",
          component: viewLazyLoad("Dashboard/Projects/Pricings"),
          meta: {
            title: "定价总览",
            requiresAuth: true,
          },
        },
        {
          path: ":projectId",
          name: "Dashboard Project Details",
          component: viewLazyLoad("Dashboard/Projects/Details"),
          meta: {
            title: "项目详情",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "technical",
      name: "Dashboard Technical",
      component: viewLazyLoad("Dashboard/Technical"),
      meta: {
        title: "技术顾问",
        requiresAuth: true,
      },
    },
    {
      path: "devops",
      name: "Dashboard Devops",
      component: viewLazyLoad("Dashboard/Devops"),
      meta: {
        title: "服务运维",
        requiresAuth: true,
      },
    },
    {
      path: "finances",
      component: viewLazyLoad("Dashboard/Finances"),
      children: [
        {
          path: "",
          name: "Dashboard Finances",
          component: viewLazyLoad("Dashboard/Finances/Home"),
          meta: {
            title: "财务中心",
            requiresAuth: true,
          },
        },
        {
          path: ":invoiceId",
          name: "Dashboard Finance Details",
          component: viewLazyLoad("Dashboard/Finances/Details"),
          meta: {
            title: "账单详情",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "supports",
      component: viewLazyLoad("Dashboard/Supports"),
      children: [
        {
          path: "",
          name: "Dashboard Supports",
          component: viewLazyLoad("Dashboard/Supports/Home"),
          meta: {
            title: "在线咨询",
            requiresAuth: true,
            showSecondarySidebar: true,
          },
        },
        {
          path: ":ticketId",
          name: "Dashboard Support Details",
          component: viewLazyLoad("Dashboard/Supports/Details"),
          meta: {
            title: "工单详情",
            requiresAuth: true,
            showSecondarySidebar: true,
          },
        },
      ],
    },
    {
      path: "referrals",
      name: "Dashboard Referrals",
      component: viewLazyLoad("Dashboard/Referrals"),
      meta: {
        title: "股东计划",
        requiresAuth: true,
      },
    },
    {
      path: "tools",
      component: viewLazyLoad("Dashboard/Tools"),
      children: [
        {
          path: "",
          name: "Dashboard Tools",
          component: viewLazyLoad("Dashboard/Tools/Home"),
          meta: {
            title: "辅助工具",
            requiresAuth: true,
          },
        },
        {
          path: "express",
          name: "Dashboard Express",
          component: viewLazyLoad("Dashboard/Tools/Express"),
          meta: {
            title: "物流管理",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "profile",
      name: "Dashboard Profile",
      component: viewLazyLoad("Dashboard/Profile"),
      meta: {
        title: "账户中心",
        requiresAuth: true,
      },
    },
  ],
}
