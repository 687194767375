/**
 * Object 合并
 *
 * @param origin
 * @param target
 * @returns Object
 */
export function objectMerge(origin, target) {
  for (let key of Object.keys(origin)) {
    if (target[key] === undefined) continue

    let item = origin[key]
    let targetItem = target[key]

    origin[key] =
      item instanceof Object && !(item instanceof Array) && Object.keys(item).length
        ? objectMerge(item, targetItem)
        : targetItem
  }

  return origin
}

/**
 * Object 翻转
 *
 * @param origin
 * @returns Object
 */
export function objectReverse(origin) {
  let result = {}

  for (let key of Object.keys(origin).reverse()) {
    result[key] = origin[key]
  }

  return result
}

/**
 * 路由 ( 视图 ) 懒加载
 *
 * @param viewName
 */
export function viewLazyLoad(viewName) {
  return () => import(/* webpackChunkName: "view-[request]" */ "@/views/" + viewName)
}
