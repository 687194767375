import Vue from "vue"
import VueRouter from "vue-router"
import Base from "@/router/Base"
import Auth from "@/router/Auth"
import Dashboard from "@/router/Dashboard"
import Manager from "@/router/Manager"

Vue.use(VueRouter)

const routes = [
  Base,
  Auth,
  Dashboard,
  Manager,
  {
    path: "*",
    redirect: "/404",
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
