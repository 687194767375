import { viewLazyLoad } from "@/assets/js/utils"

export default {
  path: "/",
  component: viewLazyLoad("Base"),
  children: [
    {
      path: "",
      name: "Home",
      component: viewLazyLoad("Base/Home"),
      meta: {
        title: "首页",
      },
    },
    {
      path: "creative-ui",
      name: "Creative UI",
      component: viewLazyLoad("Base/CreativeUI"),
      meta: {
        title: "Creative UI",
      },
    },
  ],
}
