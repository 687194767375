import { viewLazyLoad } from "@/assets/js/utils"

export default {
  path: "/manager",
  component: viewLazyLoad("Manager"),
  children: [
    {
      path: "",
      name: "Manager Home",
      component: viewLazyLoad("Manager/Home"),
      meta: {
        title: "管理面板",
        requiresAuth: true,
      },
    },
  ],
}
