import { viewLazyLoad } from "@/assets/js/utils"

export default {
  path: "/auth",
  component: viewLazyLoad("Auth"),
  children: [
    {
      path: "",
      redirect: "/auth/login",
    },
    {
      path: "login",
      name: "Login",
      component: viewLazyLoad("Auth/Login"),
      meta: {
        title: "登录账户",
      },
    },
    {
      path: "signup",
      name: "Signup",
      component: viewLazyLoad("Auth/Signup"),
      meta: {
        title: "注册账户",
      },
    },
    {
      path: "forgot_password",
      name: "Forgot Password",
      component: viewLazyLoad("Auth/ForgotPassword"),
      meta: {
        title: "忘记密码",
      },
    },
    {
      path: "logout",
      name: "Logout",
      component: viewLazyLoad("Auth/Logout"),
      meta: {
        title: "退出账户",
        requiresAuth: true,
      },
    },
  ],
}
